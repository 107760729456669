import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import parentcheckboxPreviewData from '../../../../data/previews/parent-checkbox.yml';
import SectionPlatform from '../partials/_SectionPlatform';
import pageHeroData from '../../../../data/pages/components.yml';

const ParentCheckboxSize = Loadable({
  loader: () => import('./_parentCheckboxSizePartial'),
  loading: () => null,
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sizeSelection: 'xsmall',
    };
  }

  handleSizeChange(value) {
    this.setState({
      sizeSelection: value,
    });
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Parent Checkbox"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Forms"
          tierThree="Parent Checkbox"
        />
        <V5Notice
          componentName="Parent Checkbox"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-checkboxes--docs"
        />
        <PageNavigation
          links={['Size', 'State', 'Microcopy', 'Accessibility', 'Platform']}
        />

        <Section title="Size">
          <Paragraph>
            The parent checkbox comes in all of our four available sizes,
            x-small, small, medium and large.
          </Paragraph>
          <ComponentPreview
            name="ParentCheckbox"
            name="CheckboxSize"
            previewData={parentcheckboxPreviewData.sizes}
            onChange={this.handleSizeChange.bind(this)}>
            <ParentCheckboxSize size={this.state.sizeSelection} />
          </ComponentPreview>
        </Section>

        <Section title="State">
          <Paragraph>
            A parent checkbox’s state depends on the interaction itself or a
            submission error.
          </Paragraph>
          <List>
            <li>
              <strong>Default:</strong> The focus state is sorted for you, so
              anyone navigating via keyboard or screen reader is set.
            </li>
            <li>
              <strong>Indeterminate:</strong> The indeterminate state will
              appear when any one child option is chosen, but the related parent
              is not.
            </li>
            <li>
              <strong>Disabled:</strong> This prevents the user from interacting
              with a field until certain conditions are met. Should be used
              sparingly, but a good way to prevent invalid submissions.
            </li>
          </List>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            A label should clearly and concisely describe the parent-child
            relationship in no more than a few words.
          </Paragraph>
          <DontDo
            dontText="make the label a complete sentence telling the user what to do."
            doText="name exactly what belongs in that relationship."
            imgFilename="parentcheckbox-labels"
          />
          <Paragraph>
            Check{' '}
            <Link href="/words/content-elements/labels-non-label-text/">
              UI label guidelines
            </Link>{' '}
            for more detail on case and punctuation.
          </Paragraph>

          <SectionSubhead>Nesting Relationships</SectionSubhead>
          <Paragraph>
            When nesting selectable items, be clear why or how the nested items
            are related to their parent.
          </Paragraph>
          <DontDo
            dontText="use nesting to indent selectable items without reason."
            doText="maintain a clear parent-child relationship between all nested items."
            imgFilename="parentcheckbox-relationships"
          />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Forms / Checkbox Parent Group" />

        <SectionPlatform />
      </PageWithSubNav>
    );
  }
}
